import styled, { css, DefaultTheme } from 'styled-components';

import { Wrapper as Dropdown, Trigger } from '../dropdown/dropdown.styled';
import { ShadowSizeType } from '../../../theme/type/shadow-type';
import { BreakpointSizeType } from '../../../theme/type/breakpoints-type';
import { GapSizeType } from '../../../theme/type/gap-type';
import { RadiusSizeType } from '../../../theme/type/radius-type';
import { CardVariantType } from '../../../theme/type/card-type';

const getResponsivePadding = (padding: object, theme: DefaultTheme) => {
    return Object.entries(padding).map((item, index) => {
        if (index === 0) {
            return `
                @media ${theme.breakpoints[item[0] as BreakpointSizeType].max} {
                    padding: ${theme.gap[item[1] as GapSizeType]};
                }
                @media ${theme.breakpoints[item[0] as BreakpointSizeType].min} {
                    padding: ${theme.gap[item[1] as GapSizeType]};
                }
            `;
        }
        return `
            @media ${theme.breakpoints[item[0] as BreakpointSizeType].min} {
                padding: ${theme.gap[item[1] as GapSizeType]};
            }
        `;
    });
};

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) =>
        !['padding', 'shadow', 'radius', 'variant', 'marginBottom', 'marginTop', 'fullWidth'].includes(prop),
})<{
    padding: GapSizeType | object;
    shadow?: ShadowSizeType;
    radius: RadiusSizeType;
    variant: CardVariantType;
    marginBottom?: GapSizeType;
    marginTop?: GapSizeType;
    fullWidth?: boolean;
}>`
    ${({ theme, padding, shadow, radius, variant, marginTop, marginBottom, fullWidth }) => css`
        border-radius: ${theme.radius[radius]};
        border: 0.1rem solid ${theme.card.variant[variant].border};
        min-height: 100%;
        position: relative;
        width: ${fullWidth ? '100%' : 'unset'};

        ${typeof padding === 'object' &&
        css`
            ${getResponsivePadding(padding, theme)}
        `}
        ${typeof padding === 'string' &&
        css`
            padding: ${theme.gap[padding]};
        `}
            ${shadow &&
        css`
            box-shadow: ${theme.shadow[shadow]};
        `}
            ${variant &&
        css`
            background: ${theme.card.variant[variant].background};
            color: ${theme.palette.color.white.main};
        `}
            ${marginTop &&
        css`
            margin-top: ${theme.gap[marginTop]};
        `}
            ${marginBottom &&
        css`
            margin-bottom: ${theme.gap[marginBottom]};
        `};
    `}
`;

export const Header = styled.div`
    ${({ theme }) => css`
        align-items: center;
        flex-wrap: wrap;
        display: flex;
        z-index: 999;
        margin-bottom: ${theme.gap.small};
    `}
`;

export const Title = styled.div.withConfig({
    shouldForwardProp: (prop) => !['variant'].includes(prop),
})<{
    variant: CardVariantType;
}>`
    ${({ theme, variant }) => css`
        color: ${theme.card.variant[variant].title.color};
        text-transform: uppercase;
        font-weight: 700;
        flex: 1;
        font-size: ${theme.text.size.vsmall.fontSize};
        line-height: ${theme.text.size.vsmall.lineHeight};
    `}
`;

export const Action = styled.div.withConfig({
    shouldForwardProp: (prop) => !['variant'].includes(prop),
})<{
    variant: CardVariantType;
}>`
    ${({ theme, variant }) => css`
        color: ${theme.card.variant[variant].icon.color};

        ${Dropdown}:hover ${Trigger} i {
            color: ${theme.card.variant[variant].icon.hover};
        }
    `}
`;

export const Content = styled.div.withConfig({
    shouldForwardProp: (prop) => !['variant'].includes(prop),
})<{
    variant: CardVariantType;
}>`
    ${({ theme, variant }) => css`
        flex: 1;
        color: ${theme.card.variant[variant].color};
    `}
`;

export const Footer = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: flex-end;
        z-index: 999;
        margin-top: ${theme.gap.small};
    `}
`;
