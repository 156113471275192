import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconImpersonate: React.FC = () => (
    <Icon viewBox="0 0 22 22" fill="none">
        <g clipPath="url(#clip0_3250_7473)">
            <path
                d="M11.2425 3.87268e-05C7.42135 -0.0101938 4.05871 2.00818 2.13685 5.05747C2.05166 5.19305 2.14687 5.37212 2.30473 5.37212H4.06623C4.1865 5.37212 4.29926 5.3184 4.37443 5.22375C4.54983 5.00631 4.73776 4.79654 4.93571 4.59701C5.75256 3.76561 6.70222 3.11073 7.75962 2.65538C8.8521 2.18469 10.0147 1.94422 11.215 1.94422C12.4152 1.94422 13.5778 2.18213 14.6703 2.65538C15.7277 3.11073 16.6774 3.76561 17.4942 4.59701C18.3111 5.4284 18.95 6.39794 19.3985 7.47491C19.8621 8.59026 20.0951 9.77467 20.0951 11C20.0951 12.2254 19.8596 13.4098 19.3985 14.5251C18.9525 15.6021 18.3111 16.5716 17.4942 17.403C16.6774 18.2344 15.7277 18.8893 14.6703 19.3447C13.577 19.8161 12.4018 20.058 11.215 20.0558C10.0147 20.0558 8.8521 19.8154 7.75962 19.3447C6.70429 18.8898 5.74531 18.2304 4.93571 17.403C4.73776 17.2009 4.55234 16.9912 4.37443 16.7763C4.29926 16.6816 4.184 16.6279 4.06623 16.6279H2.30473C2.14687 16.6279 2.04915 16.807 2.13685 16.9426C4.05621 19.9842 7.40381 22 11.215 22C17.1334 22 21.9393 17.137 21.9994 11.1126C22.0596 4.99096 17.2462 0.0153875 11.2425 3.87268e-05ZM8.06832 13.8651V11.9209H0.200455C0.0902048 11.9209 0 11.8289 0 11.7163V10.2837C0 10.1712 0.0902048 10.0791 0.200455 10.0791H8.06832V8.13491C8.06832 7.96351 8.26376 7.8663 8.39406 7.97375L11.9496 10.8389C11.9736 10.858 11.993 10.8825 12.0063 10.9104C12.0196 10.9383 12.0265 10.969 12.0265 11C12.0265 11.0311 12.0196 11.0617 12.0063 11.0897C11.993 11.1176 11.9736 11.142 11.9496 11.1612L8.39406 14.0263C8.26376 14.1312 8.06832 14.0365 8.06832 13.8651Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_3250_7473">
                <rect width="22" height="22" fill="white" />
            </clipPath>
        </defs>
    </Icon>
);
