import React from 'react';
import { Action, Content, Footer, Header, Title, Wrapper } from './card.styled';

import { Loading } from '../loading/loading';
import { ShadowSizeType } from '../../../theme/type/shadow-type';
import { CardVariantType } from '../../../theme/type/card-type';
import { RadiusSizeType } from '../../../theme/type/radius-type';
import { GapSizeType } from '../../../theme/type/gap-type';

interface CardPropsInterface {
    children: Array<JSX.Element> | JSX.Element | string;
    title?: string | React.ReactNode;
    footer?: Array<JSX.Element> | JSX.Element | string;
    action?: React.ReactNode;
    variant?: CardVariantType;
    shadow?: ShadowSizeType;
    radius?: RadiusSizeType;
    padding?: GapSizeType | object;
    loading?: boolean;
    marginBottom?: GapSizeType;
    marginTop?: GapSizeType;
    fullWidth?: boolean;
}

export const Card: React.FC<CardPropsInterface> = ({
    children,
    title,
    footer,
    variant = 'default',
    radius = 'medium',
    shadow,
    padding = 'medium',
    action,
    loading = false,
    marginBottom,
    marginTop,
    fullWidth,
}) => {
    const CardComponent = () => (
        <Wrapper
            padding={padding}
            shadow={shadow}
            radius={radius}
            variant={variant}
            marginTop={marginTop}
            marginBottom={marginBottom}
            fullWidth={fullWidth}
        >
            {title && (
                <Header>
                    <Title variant={variant}>{title}</Title>
                    <Action variant={variant}>{action}</Action>
                </Header>
            )}
            <Content variant={variant}>{children}</Content>
            {footer && <Footer>{footer}</Footer>}
        </Wrapper>
    );

    if (loading) {
        return (
            <Loading active={loading}>
                <CardComponent />
            </Loading>
        );
    }
    return <CardComponent />;
};
