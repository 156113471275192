import styled, { css } from 'styled-components';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<{
    isActive: boolean;
}>(
    ({ isActive }) => css`
        display: inline-flex;

        ${isActive &&
        css`
            position: relative;
            z-index: 700;
        `}
    `,
);

export const Window = styled.div.withConfig({
    shouldForwardProp: (prop) => !['position', 'positionY', 'rounded'].includes(prop),
})<{
    position: 'left' | 'center' | 'right';
    positionY?: 'bottom' | 'top';
    rounded: boolean;
}>(
    ({ theme, position, rounded, positionY }) => css`
        position: absolute;
        bottom: 100%;
        background: ${theme.palette.background.header};
        border: 0.1rem solid ${theme.palette.common.border};
        border-radius: ${rounded ? '20rem' : theme.radius.large};
        transform: translate(-50%, -0.5rem);
        box-shadow: ${theme.shadow.large};

        ${positionY === 'bottom' &&
        css`
            bottom: unset;
            top: 150%;
        `}

        &:before,
        &:after {
            top: 100%;
            border: solid transparent;
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;

            ${positionY === 'bottom' &&
            css`
                top: unset;
                bottom: 100%;
                transform: rotate(180deg);
            `}
        }

        &:before {
            border-color: rgba(0, 0, 0, 0);
            border-top-color: ${theme.palette.common.border};
            border-width: 1.1rem;
            margin-left: -1.1rem;
        }

        &:after {
            border-color: rgba(0, 0, 0, 0);
            border-top-color: ${theme.palette.background.header};
            border-width: 1rem;
            margin-left: -1rem;
        }

        ${position === 'left' &&
        css`
            transform: translate(0, -0.5rem);
            left: 0;

            &:before,
            &:after {
                left: 4rem;
            }
        `}

        ${position === 'center' &&
        css`
            transform: translate(-50%, -0.5rem);
            left: 50%;

            &:before,
            &:after {
                left: 50%;
            }
        `}

        ${position === 'right' &&
        css`
            transform: translate(0, -0.5rem);
            right: 0;

            &:before,
            &:after {
                right: 4rem;
            }
        `}
    `,
);

export const Action = styled.div`
    display: flex;
    white-space: nowrap;
    gap: 1rem;
    padding: 1rem 1rem;
`;

export const Title = styled.div.withConfig({
    shouldForwardProp: (prop) => !['variant'].includes(prop),
})<{
    variant: 'positive' | 'negative';
}>(
    ({ theme, variant }) => css`
        font-weight: 700;
        text-align: center;
        color: ${theme.palette.color[variant].main};
        margin-bottom: ${theme.gap.xsmall};
        padding: 1rem;
        border-bottom: 0.1rem solid ${theme.palette.common.border};
    `,
);

export const Content = styled.div(
    ({ theme }) => css`
        text-align: center;
        font-weight: 400;
        font-size: ${theme.text.size.vsmall.fontSize};
        line-height: ${theme.text.size.vsmall.lineHeight};
        margin-top: ${theme.gap.xsmall};
        border-top: 0.1rem solid ${theme.palette.common.border};
        padding: 1rem;
    `,
);
