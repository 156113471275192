import styled, { css } from 'styled-components';
import { PositionType } from '../../../utils/calculate-position';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['block'].includes(prop),
})<{
    block: boolean;
}>`
    ${({ block }) => css`
        position: relative;
        display: ${block ? 'block' : 'inline-flex'};
        z-index: 900;

        &:hover {
            z-index: 910;
        }
    `}
`;

export const Trigger = styled.div.withConfig({
    shouldForwardProp: (prop) => !['block'].includes(prop),
})<{
    block: boolean;
}>`
    ${({ block }) => css`
        display: ${block ? 'block' : 'inline-flex'};
        padding-left: 1rem;
        margin-left: -1rem;
    `}
`;
export const Content = styled.div.withConfig({
    shouldForwardProp: (prop) => !['isOpen', 'position', 'arrowPosition'].includes(prop),
})<{
    position?: PositionType;
    isOpen: boolean;
    arrowPosition?: number;
}>`
    ${({ theme, isOpen, position, arrowPosition }) => css`
        position: absolute;
        z-index: 9999;
        white-space: nowrap;
        display: ${isOpen ? 'block' : 'none'};

        &:after {
            border: 0.8rem solid rgba(0, 0, 0, 0);
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        --top-position: -1.6rem;
        --bottom-position: 1.6rem;
        --horizontal-position: 0rem;
        --horizontal-position-negative: 0rem;

        ${arrowPosition &&
        css`
            --arrow-position: ${arrowPosition}rem;
        `}

        ${!arrowPosition &&
        css`
            --arrow-position: 1.6rem;
        `}

        ${position === 'bottom' &&
        css`
            transform: translateY(var(--bottom-position));

            &:after {
                left: 50%;
                transform: translateX(-50%);
                bottom: 100%;
                border-bottom-color: ${theme.palette.color.secondary.main};
            }
        `}

        ${position === 'top' &&
        css`
            transform: translateY(var(--top-position));

            &:after {
                top: 100%;
                border-top-color: ${theme.palette.color.secondary.main};
                left: 50%;
                transform: translateX(-50%);
            }
        `}

        ${position === 'top-left' &&
        css`
            transform: translate(var(--horizontal-position-negative), var(--top-position));

            &:after {
                top: 100%;
                border-top-color: ${theme.palette.color.secondary.main};
                left: var(--arrow-position);
            }
        `}

        ${position === 'top-right' &&
        css`
            transform: translate(var(--horizontal-position), var(--top-position));

            &:after {
                top: 100%;
                border-top-color: ${theme.palette.color.secondary.main};
                right: var(--arrow-position);
            }
        `}

        ${position === 'bottom-right' &&
        css`
            transform: translate(var(--horizontal-position), var(--bottom-position));

            &:after {
                right: var(--arrow-position);
                bottom: 100%;
                border-top-color: rgba(0, 0, 0, 0);
                border-bottom-color: ${theme.palette.color.secondary.main};
            }
        `}

        ${position === 'bottom-left' &&
        css`
            transform: translate(var(--horizontal-position-negative), var(--bottom-position));

            &:after {
                left: var(--arrow-position);
                bottom: 100%;
                border-top-color: rgba(0, 0, 0, 0);
                border-bottom-color: ${theme.palette.color.secondary.main};
            }
        `}
    `}
`;

export const ContentInner = styled.div.withConfig({
    shouldForwardProp: (prop) => !['size'].includes(prop),
})<{
    size: 'small' | 'medium';
}>`
    ${({ size }) => css`
        max-height: calc(100vh - 15rem);
        overflow: auto;
        padding: 1.5rem;

        ${size === 'small' &&
        css`
            padding: 0 1rem;
        `}
    `}
`;
